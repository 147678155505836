<template>
<a-form-model
  class="sale-requisite-modal"
  ref="saleRequisiteForm"
  :model="form"
  :rules="form"
>
  <a-row class="mb-0">
    <a-col :span="6" class="pr-1">
      <a-form-model-item ref="currency_id" :label="`${$t('common.currency')}`" prop="currency_id">
        <a-select v-model="form.type.value" disabled>
          <a-select-option v-for="type in form.types" :key="type.key">
            {{ type.key }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-col>
    <a-col :span="18" class="pr-1">
      <a-form-model-item ref="amount" :label="`${$t('common.address')}`" prop="amount">
        <a-input v-model="form.address" :placeholder="$t('common.address')"/>
      </a-form-model-item>
    </a-col>
  </a-row>
  <a-row class="mb-0">
    <a-col :span="24" class="text-right">
      <div class="text-right mt-3">
        <a-button type="primary" @click="submit" :class="{'disabled': loading}" :loading="loading">
          {{ $t('buttons.btnSave') }}
        </a-button>
      </div>
    </a-col>
  </a-row>
</a-form-model>
</template>

<script>
import { reactive, ref } from 'vue'
import apiClient from '@/services/axios'

export default {
  props: ['accountObj', 'wallet'],
  name: 'CreateUSDTWalletPopup',
  data() {
    const form = reactive({
      id: null,
      type: { key: 'BSC', value: 'BSC' },
      types: [{
        key: 'BSC',
        value: 'BSC',
      }],
      address: '',
    })
    const loading = ref(false)
    if (this.wallet) {
      console.log(this.wallet)
      form.id = this.wallet.id
      form.type = { key: this.wallet.type, value: this.wallet.type }
      form.address = this.wallet.address
    }
    return {
      loading,
      form,
    }
  },
  methods: {
    async createWallet() {
      try {
        this.loading = true
        const payload = {
          account_id: this.accountObj.id,
          type: this.form.type.value,
          address: this.form.address,
        }
        await apiClient.post('/admin/accounts/payouts/wallets', payload)
        this.$emit('created')
      } catch (e) {
        this.$notification.error({
          message: `${this.$t('messages.errorCreatingRequisite')}`,
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    async updateWallet() {
      try {
        this.loading = true
        const payload = {
          account_id: this.accountObj.id,
          type: this.form.type.value,
          address: this.form.address,
        }
        await apiClient.patch(`/admin/accounts/payouts/wallets/${this.wallet.id}`, payload)
        this.$emit('created')
      } catch (e) {
        this.$notification.error({
          message: `${this.$t('messages.errorCreatingRequisite')}`,
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    async submit() {
      if (this.wallet === null) {
        await this.createWallet()
      } else {
        await this.updateWallet()
      }
    },
  },
}
</script>

<style scoped>

</style>
