<template>
  <div>
      <a-form-model
        ref="interconnectForm"
        :model="interconnectForm"
      >
        <a-row>
          <a-col :span="24">
            <a-form-model-item ref="connectedTo" label="Connect To" prop="agent_id">
              <a-select v-model="interconnectForm.agent_id" :placeholder="$t('placeholders.selectAgent')" class="width-150"
                        @change="handleAgentChanged">
                <a-select-option :value="0">
                  [Select Agent]
                </a-select-option>
                <a-select-option v-for="agent in accountsData" :key="agent.id">
                  {{ agent.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <span class="font-weight-bold">Options:</span>
            <a-card>
              <span slot="title" class="font-weight-normal font-size-14">Notifications</span>
              <a-row>
                <a-col :span="12">
                  <div class="mb-2">BUY-orders</div>
                  <div>
                    <a-checkbox v-model="interconnectForm.options.notifications.buy_orders.new">
                      <i class="fe fe-credit-card" /> {{ 'New orders' }}
                    </a-checkbox>
                  </div>
                  <div>
                    <a-checkbox v-model="interconnectForm.options.notifications.buy_orders.chat_messages">
                      <i class="fe fe-message-circle" /> {{ 'Chat messages' }}
                    </a-checkbox>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="mb-2">SELL-orders</div>
                  <div>
                    <a-checkbox v-model="interconnectForm.options.notifications.sell_orders.new">
                      <i class="fe fe-credit-card" /> {{ 'New orders' }}
                    </a-checkbox>
                  </div>
                  <div>
                    <a-checkbox v-model="interconnectForm.options.notifications.sell_orders.chat_messages">
                      <i class="fe fe-message-circle" /> {{ 'Chat messages' }}
                    </a-checkbox>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </a-form-model>
      <a-row class="mt-3">
        <a-col :span="14">
          <a-button @click="closeModal">
            Cancel
          </a-button>
          <a-button class="ml-2" type="danger" icon="delete" v-if="interconnect" :loading="deleteLoading" @click="deleteSubmit">
            Delete
          </a-button>
        </a-col>
        <a-col :span="10" class="text-right">
          <a-button type="primary" @click="onSubmit" :loading="submitLoading">
            {{ interconnect ? 'Save' : 'Add' }}
          </a-button>
        </a-col>
      </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'interconnectModal',
  props: ['interconnect', 'agentId'],
  computed: {
    // --
  },
  data() {
    return {
      submitLoading: false,
      deleteLoading: false,
      accountsData: [],
      interconnectId: this.interconnect && this.interconnect.id ? this.interconnect.id : 0,
      interconnectForm: {
        agent_id: this.interconnect && this.interconnect.connected_id ? this.interconnect.connected_id : 0,
        options: {
          notifications: {
            enabled: true,
            buy_orders: {
              new: this.interconnect && this.interconnect.options ? this.interconnect.options.notifications.buy_orders.new : false,
              chat_messages: this.interconnect && this.interconnect.options ? this.interconnect.options.notifications.buy_orders.chat_messages : false,
            },
            sell_orders: {
              new: this.interconnect && this.interconnect.options ? this.interconnect.options.notifications.sell_orders.new : false,
              chat_messages: this.interconnect && this.interconnect.options ? this.interconnect.options.notifications.sell_orders.chat_messages : false,
            },
          },
        },
        is_active: true,
      },
    }
  },
  mounted () {
    this.getAccounts()
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    submitDeleteModal(recordId) {
      this.$emit('deleteModalFunction', recordId)
    },
    onSubmit() {
      this.$refs.interconnectForm.validate(valid => {
        if (valid) {
          this.interconnect ? this.editSubmit() : this.addSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addSubmit() {
      this.submitLoading = true
      return ApiService.createAgentInterconnect(this.agentId, this.interconnectForm).then(
        (response) => {
          this.$message.success('New interconnect created')
          this.submitLoading = false
          this.submitModal(response)
        },
      ).catch(error => {
        this.$message.error('Error while creating interconnect')
        console.log(error)
        this.submitLoading = false
      })
    },
    editSubmit() {
      this.submitLoading = true
      return ApiService.updateAgentInterconnect(this.agentId, this.interconnectId, this.interconnectForm).then(
        (response) => {
          this.$message.info('Interconnect updated')
          this.submitLoading = false
          this.submitModal(response, true)
        },
      ).catch(error => {
        this.$message.error('Error while updating interconnect')
        console.log(error)
        this.submitLoading = false
      })
    },
    deleteSubmit() {
      this.deleteLoading = true
      return ApiService.deleteAgentInterconnect(this.agentId, this.interconnectId).then(
        (response) => {
          this.$message.info('Interconnect deleted')
          this.deleteLoading = false
          this.submitDeleteModal(this.interconnectId)
        },
      ).catch(error => {
        this.$message.error('Error while deleting interconnect')
        console.log(error)
        this.deleteLoading = false
      })
    },
    handleAgentChanged(value) {
      console.log(`agent selected ${value}`)
    },
    async getAccounts() {
      return ApiService.getAccounts({ short: true }).then((response) => {
        this.accountsData = response.data.data
      }).catch(error => {
        this.$message.error('Error while loading agents')
        console.log(error)
      })
    },
  },
}
</script>
<style lang="scss">
</style>
<style scoped>
</style>
