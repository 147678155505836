<template>
  <div>
    <a-form-model
      ref="addRequisiteForm"
      :model="addRequisiteForm"
      :rules="addRequisiteRules"
    >
      <a-row>
        <a-col :span="12" class="pr-1">
          <a-form-model-item ref="psystem_id" :label="$t('labels.paymentMethod')" prop="psystem_id">
            <a-select v-model="addRequisiteForm.psystem_id"
                      :placeholder="$t('placeholders.selectPaySystem')"
                      @change="handlePsystemChange"
                      :loading="psystemsLoading"
                      :disabled="psystemsLoading"
            >
<!--              <a-select-option :value="0" >-->
<!--                Выберите платежную систему-->
<!--              </a-select-option>-->
              <a-select-option v-for="psystem in psystems" :key="psystem.id" >
                <span role="img">
                  <img :src="psystem.img_path" width="16" height="16" >
                </span>
                {{ psystem.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1 pr-1">
          <a-form-model-item ref="currency_id" :label="$t('common.currency')" prop="currency_id">
            <a-select v-model="addRequisiteForm.currency_id"
                      :placeholder="$t('placeholders.selectCurrency')"
                      @change="handleCurrencyChange"
                      :disabled="!addRequisiteForm.psystem_id"
            >
              <a-select-option v-for="currency in currencies" :key="currency.id" >
                {{ currency.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1">
          <a-form-model-item ref="max_amount" :label="$t('labels.paymentLimit')" prop="max_amount">
            <a-input-number
              v-model="addRequisiteForm.max_amount"
              class="w-100"
              :default-value="1000"
              :min="1"
              :max="100000"
              :formatter="value => currency && currency.abbr ? `${value}${currency.abbr}` : `${value} `"
              :parser="value => value.replace(currency && currency.abbr ? `${currency.abbr}` : ' ', '')"
              :disabled="!(addRequisiteForm.psystem_id > 0 && addRequisiteForm.currency_id > 0)"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="6" class="pr-1">
          <a-form-model-item ref="address_type_id" :label="$t('labels.addressType')" prop="address_type_id">
            <a-select v-model="addRequisiteForm.address_type_id"
                      :placeholder="$t('placeholders.selectAddressType')"
                      @change="handleAddressTypeChange"
                      :disabled="!addRequisiteForm.psystem_id || psystemsLoading"
            >
              <a-select-option v-for="type in addressTypes" :key="type.id" >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="9" class="pl-1 pr-1">
          <a-form-model-item ref="address" :label="$t('common.address')" prop="address">
            <a-input v-model="addRequisiteForm.address" :placeholder="$t('placeholders.requisiteAddress')" />
          </a-form-model-item>
        </a-col>
        <a-col :span="9" class="pl-1">
          <a-form-model-item ref="name" :label="$t('common.name')" prop="name">
            <a-input v-model="addRequisiteForm.name" :placeholder="`${$t('pre.eg')} Ivan Ivanovich I.`" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="comment" :label="$t('common.comment')" prop="comment">
            <a-textarea v-model="addRequisiteForm.comment" :placeholder="$t('placeholders.requisiteAgentComment')" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <span>
            {{ $t('buttons.btnActivate') }}
            <a-switch v-model="addRequisiteForm.is_active" />
          </span>
        </a-col>
      </a-row>
    </a-form-model>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          {{ $t('buttons.btnCancel') }}
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button type="primary" @click="onSubmit" :loading="submitLoading">
          {{ requisiteInfo ? $t('buttons.btnSave') : $t('buttons.btnAdd') }}
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'addRequisite',
  props: ['requisiteInfo', 'accountObj'],
  computed: {
    addressTypes() {
      if (this.psystems && this.psystems[0] && this.selectedPsystemId) {
        const ind = this.psystems.findIndex((x) => x.id === this.selectedPsystemId)
        if (ind > -1) { return this.psystems[ind].address_types }
      }
      return []
    },
  },
  data() {
    return {
      submitLoading: false,
      psystemsLoading: false,
      psystems: undefined,
      currencies: this.requisiteInfo ? this.requisiteInfo.psystem.currencies : undefined,
      currency: this.requisiteInfo ? this.requisiteInfo.currency : undefined,
      selectedPsystem: undefined,
      selectedPsystemId: this.requisiteInfo ? this.requisiteInfo.psystem_id : undefined,
      addRequisiteForm: {
        name: this.requisiteInfo ? this.requisiteInfo.name : undefined,
        address: this.requisiteInfo ? this.requisiteInfo.address : undefined,
        account_id: this.accountObj.id,
        psystem_id: this.requisiteInfo ? this.requisiteInfo.psystem_id : undefined,
        currency_id: this.requisiteInfo ? this.requisiteInfo.currency_id : undefined,
        max_amount: this.requisiteInfo ? this.requisiteInfo.max_amount : 1000,
        is_active: this.requisiteInfo ? this.requisiteInfo.is_active : 0,
        comment: this.requisiteInfo ? this.requisiteInfo.comment : '',
        address_type_id: this.requisiteInfo ? this.requisiteInfo.address_type.id : undefined,
      },
      addRequisiteRules: {
        name: [{ required: true, message: `${this.$t('validations.requisiteNameRequired')}`, trigger: 'change' }],
        address: [{ required: true, message: `${this.$t('validations.requisiteAddressRequired')}`, trigger: 'change' }],
        psystem_id: [{ required: true, message: `${this.$t('validations.psystemRequired')}`, trigger: 'change' }],
        currency_id: [{ required: true, message: `${this.$t('validations.currencyRequired')}`, trigger: 'change' }],
        address_type_id: [{ required: true, message: `${this.$t('validations.addressTypeRequired')}`, trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.getPsystems()
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    onSubmit() {
      this.$refs.addRequisiteForm.validate(valid => {
        if (valid) {
          this.requisiteInfo ? this.editRequisiteSubmit() : this.addRequisiteSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.addRequisiteForm.resetFields()
    },
    handlePsystemChange(objId) {
      // const ps = this.psystems.find(obj => obj.id === objId)
      this.selectedPsystemId = objId
      this.selectedPsystem = this.psystems.find(obj => obj.id === objId)
      this.currencies = this.accountObj.id === 999 ? this.selectedPsystem.currencies : this.selectedPsystem.currencies.filter((cur) => cur.id === this.accountObj.currency_id)
      if (typeof this.currencies[0] !== 'undefined') {
        this.addRequisiteForm.currency_id = this.currencies[0].id
        this.currency = this.currencies[0]
      }
      console.log(this.currencies)
      console.log('address_types', this.addressTypes)
    },
    handleCurrencyChange(objId) {
      const cur = this.currencies.find(obj => obj.id === objId)
      this.currency = cur
      console.log(this.currency)
    },
    handleAddressTypeChange(selected) {
      // ---
    },
    addRequisiteSubmit() {
      this.submitLoading = true
      return ApiService.createRequisite(this.addRequisiteForm).then((response) => {
        this.$message.success(`Requisites added: ${response.name}`)
        this.submitLoading = false
        this.submitModal(response)
      }).catch(error => {
        console.log(error)
        this.$message.error(`Error while creating new requisites: ${error.message}`)
        this.submitLoading = false
      })
    },
    editRequisiteSubmit() {
      this.submitLoading = true
      return ApiService.updateRequisite(this.requisiteInfo.id, this.addRequisiteForm).then((response) => {
        this.$message.success(`Requisites updated for ${response?.name}`)
        this.submitLoading = false
        this.submitModal(response, true)
      }).catch(error => {
        console.log(error)
        this.$message.error(`Error while updating requisites: ${error.message}`)
        this.submitLoading = false
      })
    },
    async getPsystems() {
      this.psystemsLoading = true
      return ApiService.getPsystems(true, this.accountObj.currency_id).then(
        response => {
          this.psystems = response.filter(obj => obj.id !== 6)
          console.log('getPsystems', this.psystems)
          this.psystemsLoading = false
        },
        error => {
          this.psystemsLoading = false
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
