<template>
  <div>
    <div slot="title" class="mb-4">
      <div class="d-flex">
        <h6>Расчеты - {{ accountName }}</h6>
        <div class="ml-auto">
            <div class="close-modal-icon">
              <a href="javascript:" class="text-gray-6 font-size-16" @click="closeModal"><a-icon type="close" /></a>
            </div>
        </div>
      </div>
    </div>
    <a-form-model
      ref="addRequisiteForm"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="agent" label="Агент" prop="agent">
            <a-select v-model="accountId" placeholder="Выберите агента" @change="handleAccountChange">
              <a-select-option v-for="account in accountsData" :key="account.id" >
                {{ account.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pl-2">
          <a-form-model-item ref="month" label="Расчетный месяц" prop="month">
            <a-select v-model="selectedMonth" placeholder="Выберите месяц" @change="handleMonthChange">
              <a-select-option :value="0" :key="0">
                За весь период
              </a-select-option>
              <a-select-option v-for="(monthName, index) in availableMonths" :key="index+1" >
                {{ monthName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-3" v-if="!statsLoading">
        <a-col :span="8" class="text-center">
          <a-statistic
            title="Текущий баланс"
            :value="stats.balance"
            :precision="2"
            suffix="$"
            class="text-gray-5"
            style="margin-right: 10px"
          >
            <template #prefix>
              <a-icon type="wallet" />
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="8" class="text-center">
          <a-statistic
            title="Оборот за период"
            :value="stats.payments.value"
            :precision="2"
            suffix="$"
            class="text-gray-5"
            style="margin-right: 10px"
          >
            <template #prefix>
              <a-icon type="sync" />
            </template>
          </a-statistic>
        </a-col>
        <a-col :span="8" class="text-center">
          <div style="color: rgba(0,0,0,0.45)" class="mb-1">В работе за период</div>
          <div class="font-size-24">
            <a-icon type="clock-circle" class="mr-2" />
            <span>{{ stats.rotation.values.h }}<span class="font-size-16">ч</span></span>
            <span class="ml-2 font-size-16">{{ stats.rotation.values.m }}<span class="font-size-16">м</span></span>
          </div>
        </a-col>
      </a-row>
      <div v-else class="width-100p text-center mt-1 mb-4">
        <a-spin size="large" />
      </div>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-card class="billing-stat-card">
            <a-row v-if="!statsLoading">
              <a-col :span="12">
                <a-statistic
                  title="Заработано"
                  :value="stats.rewards.value"
                  :precision="2"
                  suffix="$"
                  :value-style="{ color: '#3f8600' }"
                  style="margin-right: 10px"
                >
                  <template #prefix>
                    <a-icon type="arrow-up" />
                  </template>
                </a-statistic>
              </a-col>
              <a-col :span="12">
                <a-statistic
                  title="Заявки"
                  :value="stats.rewards.count"
                  :precision="0"
                  suffix="шт"
                  style="margin-left: 20px"
                >
                  <template #prefix>
                    <a-icon type="file-done" />
                  </template>
                </a-statistic>
              </a-col>
            </a-row>
            <div v-else class="width-100p text-center mt-1 mb-4">
              <a-spin size="large" />
            </div>
            <div class="mt-2">
              <a-table
                :columns="rewardsColumns"
                :data-source="rewardsData"
                :row-key="record => record.id"
                :loading="rewardsLoading"
                :pagination="rewardsPagination"
                @change="handleRewardsChange"
                :scroll="{ y: 280 }"
                :bordered="false"
                size="small"
                class="ant-small-table"
              >
                <template v-slot:charged_at="charged_at">
                  {{ $moment.parseZone(charged_at).format('DD.MM.YY') }}
<!--                  <span class="small text-gray-5"><a-icon type="clock-circle" /> {{ $moment(charged_at).format('HH:mm') }}</span>-->
                </template>
                <template v-slot:amount="amount, record">
                  {{ amount }} $
                  <span class="text-gray-5" v-if="record.amount_f">
                    ({{ record.amount_f }}+{{ record.amount_p }})
                  </span>
                </template>
              </a-table>
            </div>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card class="billing-stat-card">
            <div class="d-flex" v-if="!statsLoading">
              <a-statistic
                title="Списано"
                :value="stats.accruals.value"
                :precision="2"
                suffix="$"
                class="demo-class"
                :value-style="{ color: '#cf1322' }"
              >
                <template #prefix>
                  <a-icon type="arrow-down" />
                </template>
              </a-statistic>
              <div class="ml-auto">
                <span class="font-size-18">
                  <a-tooltip title="Списать" placement="bottom">
                    <a href="javascript:" @click="showAddAccrualModal"><a-icon type="plus" class="text-primary" /></a>
                  </a-tooltip>
                </span>
              </div>
            </div>
            <div v-else class="width-100p text-center mt-1 mb-4">
              <a-spin size="large" />
            </div>
            <div class="mt-2">
              <a-table
                :columns="accrualsColumns"
                :data-source="accrualsData"
                :row-key="record => record.id"
                :loading="accrualsLoading"
                :pagination="accrualsPagination"
                @change="handleAccrualsChange"
                :scroll="{ y: 360 }"
                :bordered="false"
                size="small"
                class="ant-small-table"
                >
                <template v-slot:accrual_at="accrual_at">
                  {{ $moment.parseZone(accrual_at).format('DD.MM.YY') }}
                  <span class="small d-block text-gray-5"><a-icon type="clock-circle" /> {{ $moment.parseZone(accrual_at).format('HH:mm') }}</span>
                </template>
                <template v-slot:amount="amount">
                  {{ amount }} $
                </template>
                <template v-slot:operation="text, record">
                  <a-tooltip title="Редактировать" placement="bottom">
                    <span class="mr-2 font-size-14">
                      <a href="javascript:" @click="showEditAccrualModal(record)"><a-icon type="edit" /></a>
                    </span>
                  </a-tooltip>
                  <a-tooltip title="Удалить запись" placement="bottom">
                    <a-popconfirm
                      title="Удалить запись?"
                      @confirm="deleteAccrual(record.id)"
                    >
                      <span class="font-size-14">
                        <a href="javascript:"><a-icon type="delete" class="text-danger" /></a>
                      </span>
                    </a-popconfirm>
                  </a-tooltip>
                </template>
              </a-table>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </a-form-model>
    <a-modal v-model="addAccrualModal"
             :destroyOnClose="true"
             :title="accrualObj ? `Редактировать списание, ${accountName}` : `Списать средства, ${accountName}`"
             :footer="null"
             class="add-accrual-modal"
             width="480px"
    >
      <add-accrual-component
        :accrual-info="accrualObj"
        :account-id="accountId"
        @closeModalFunction="closeAccrualModal"
        @submitModalFunction="accrualModalSubmit" />
    </a-modal>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import addAccrualComponent from '@/views/accounts/billing/addAccrualModal.vue'
import ApiService from '@/services/api/api.service'

const accrualsColumns = [
  {
    title: 'Дата',
    dataIndex: 'accrual_at',
    key: 'accrual_at',
    width: '40%',
    scopedSlots: { customRender: 'accrual_at' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    width: '30%',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: '',
    dataIndex: 'operation',
    width: '30%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
const rewardsColumns = [
  {
    title: 'Дата',
    dataIndex: 'charged_at',
    key: 'charged_at',
    width: '30%',
    scopedSlots: { customRender: 'charged_at' },
  },
  {
    title: 'Заявка',
    dataIndex: 'trec_id',
    key: 'trec_id',
    width: '25%',
    scopedSlots: { customRender: 'payment' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    width: '45%',
    align: 'left',
    scopedSlots: { customRender: 'amount' },
  },
]

export default {
  name: 'billingModal',
  props: ['accountObj'],
  components: {
    addAccrualComponent,
  },
  computed: {
    availableMonths() {
      const avm = []
      const currentMonth = new Date().getMonth()
      this.monthNames.forEach(function (value, i) {
        if (i <= currentMonth) { avm.push(value) }
      })
      console.log('avm', avm)
      return avm
    },
    accountName() {
      const acIndex = this.accountsData.findIndex(obj => obj.id === this.accountId)
      return this.accountsData[acIndex]?.name ?? ''
    },
  },
  data() {
    return {
      submitLoading: false,
      statsLoading: false,
      rewardsLoading: false,
      accrualsLoading: false,
      addAccrualModal: false,
      accrualsData: [],
      rewardsData: [],
      accountsData: [],
      accountId: this.accountObj.id,
      accrualObj: null,
      accrualsColumns,
      rewardsColumns,
      rewardsPagination: { pageSize: 10 },
      accrualsPagination: { pageSize: 10 },
      monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      selectedMonth: new Date().getMonth() + 1,
      stats: {
        balance: 0,
        rewards: {
          count: 0,
          value: 0,
        },
        accruals: {
          count: 0,
          value: 0,
        },
        payments: {
          count: 0,
          value: 0,
        },
        rotation: {
          values: {
            h: 0,
            m: 0,
            s: 0,
          },
        },
      },
    }
  },
  mounted () {
    this.getAccounts().then(() => this.refreshData())
  },
  methods: {
    handleMonthChange() {
      console.log(this.selectedMonth)
      this.refreshData()
    },
    handleAccountChange() {
      console.log(this.accountId)
      this.refreshData()
    },
    refreshData() {
      this.getStat().then(() => { this.fetchRewards(); this.fetchAccruals() })
    },
    closeModal() {
      this.$emit('closeModalFunction', this.stats, this.accountId)
    },
    showAddAccrualModal() {
      this.accrualObj = null
      this.addAccrualModal = true
    },
    showEditAccrualModal(obj) {
      this.accrualObj = obj
      this.addAccrualModal = true
    },
    accrualModalSubmit() {
      this.addAccrualModal = false
      this.refreshData()
    },
    closeAccrualModal() {
      this.addAccrualModal = false
    },
    async getStat() {
      this.statsLoading = true
      return ApiService.getAccountStats(this.accountId, this.selectedMonth).then((response) => {
        this.stats = response.data.data
        this.statsLoading = false
      }).catch(error => { console.log(error); this.statsLoading = false })
    },
    handleRewardsChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.rewardsPagination }
      pager.current = pagination.current
      this.rewardsPagination = pager
      this.fetchRewards({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
      })
    },
    handleAccrualsChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.accrualsPagination }
      pager.current = pagination.current
      this.accrualsPagination = pager
      this.fetchAccruals({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
      })
    },
    async fetchRewards(params = {}) {
      this.rewardsLoading = true
      const reqData = { results: 10, ...params }
      return ApiService.getAccountRewards(this.accountId, this.selectedMonth, reqData).then((response) => {
        const pagination = { ...this.rewardsPagination }
        pagination.total = response.data.meta.total
        this.rewardsLoading = false
        this.rewardsData = response.data.data
        this.rewardsPagination = pagination
      }).catch(error => {
        console.log(error)
        this.rewardsLoading = false
      })
    },
    async fetchAccruals(params = {}) {
      this.accrualsLoading = true
      const reqData = { results: 10, ...params }
      return ApiService.getAccountAccruals(this.accountId, this.selectedMonth, reqData).then((response) => {
        const pagination = { ...this.accrualsPagination }
        pagination.total = response.data.meta.total
        this.accrualsLoading = false
        this.accrualsData = response.data.data
        this.accrualsPagination = pagination
      }).catch(error => {
        console.log(error)
        this.accrualsLoading = false
      })
    },
    async deleteAccrual(accrualId) {
      return ApiService.deleteAccountAccrual(accrualId).then(() => {
        const index = this.accrualsData.findIndex(obj => obj.id === accrualId)
        this.accrualsData.splice(index, 1)
        this.$notification.success({
          message: 'Запись удалена',
          description: '',
        })
        this.getStat()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Не удалось удалить запись',
          description: error.message,
        })
      })
    },
    async getAccounts() {
      const url = '/admin/accounts?short'
      return apiClient.get(url).then((response) => {
        this.accountsData = response.data.data
        console.log('accounts', this.accountsData)
      }).catch(error => { console.log(error) })
    },
  },
}
</script>
<style lang="scss">
  .ant-small-table {
    border: 0px;
    .ant-table-column-title, .ant-table-tbody, .ant-pagination {
      font-size: 12px;
      font-weight: normal;
    }
    .ant-table-body { height: 280px; max-height: 280px; }
    .ant-table-row { border: 0px; }
    .ant-table-small {
      border: 0px;
      td, tr { border: 0px !important; }
    }
    .ant-table-placeholder {
      position: absolute;
      top: 0;
      margin-top: 3rem;
      width: 100%;
    }
  }
  .billing-stat-card {
    height: 480px;
    max-height: 480px;
  }
  .close-modal-icon {
    margin-top: -0.5rem;
    margin-right: 0rem;
  }
</style>
<style scoped>
</style>
