<script>
export default {
  name: 'AgentAvatar',
  props: ['agent', 'iconSize'],
  data() {
    return {
      sex: this.agent?.sex ?? 'male',
      iconWidth: this.iconSize ?? 16,
    }
  },
}
</script>

<template>
  <span>
    <img v-if="sex === 'male'" src="/resources/images/avatars/user-agent-m.png" :width="iconWidth" />
    <img v-else src="/resources/images/avatars/user-agent-w.png" :width="iconWidth"/>
  </span>
</template>

<style scoped lang="scss">

</style>
